import React, { useEffect, useState } from "react";
import Slider from "@mui/material/Slider";
import { BsCheckLg, BsSearch } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import { fetchCityData } from "../../redux/action/cityData";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import backgroundimg from "../../asset/images/bckground.png";
import filter from "../../asset/images/filter.svg";
import { fetchAllProjectList } from "../../redux/action/allProjectList";
import { fetchCity } from "../../redux/action/cityName";
import { fetchSummary } from "../../redux/action/summary";
import { cityDataSelector } from "../../redux/reducer/cityData";
import { citySelector } from "../../redux/reducer/cityName";
import { sumarrySelector } from "../../redux/reducer/summary";
import "./style.scss";
import allProjectList from "../../redux/reducer/allProjectList";

const HomepageSearch = () => {
  const [active, setActive] = useState("0");
  const [homepageSearchFilterTabs, setHomepageSearchFilterTabs] = useState("");
  const [homepageSearchFilterRoom, setHomepageSearchFilterRoom] = useState("");
  const [homepageSearchFilterType, setHomepageSearchFilterType] = useState("");
  const [dispatchFinalState, setDispatchFinalState] = useState([]);

  //for tabs
  const handleClick = (e) => {
    setActive(e.target.id);
    setHomepageSearchFilterTabs(e.target.value);
  };
  //for rooms
  const handleClickRoom = (e) => {
    setActive(e.target.id);
    setHomepageSearchFilterRoom(e.target.value);
  };
  //for property type
  const handleClickType = (e) => {
    setActive(e.target.id);
    setHomepageSearchFilterType(e.target.value);
  };

  // useEffect(() => {
  //   setDispatchFinalState([
  //     "",
  //     homepageSearchFilterType || homepageSearchFilterTabs,
  //     "",
  //     "",
  //     "",
  //     "",
  //     "",
  //     homepageSearchFilterBudget[0],
  //     homepageSearchFilterBudget[1],
  //     "",
  //     "",
  //     "",
  //     homepageSearchFilterRoom,
  //   ]);
  //   console.log(...dispatchFinalState);
  // }, [setDispatchFinalState]);

  const FinalDispatchButton = () => {
    // dispatch(allProjectList(...dispatchFinalState));
    console.log("Dispatched FinalState.......");
  };

  let min = 3000000;
  let max = 40000000;
  const [homepageSearchFilterBudget, setHomepageSearchFilterBudget] = useState([
    min,
    max,
  ]);
  const handleRange = (e) => {
    setActive(e.target.id);
    setHomepageSearchFilterBudget(e.target.value);
    console.log();
  };

  const customMarks = [
    {
      value: 3000000,
      label: "₹30L",
    },
    {
      value: 10000000,
      label: "₹1CR",
    },
    {
      value: 20000000,
      label: "₹2CR",
    },
    {
      value: 30000000,
      label: "₹3CR",
    },
    {
      value: 40000000,
      label: "₹4CR",
    },
  ];

  const dispatch = useDispatch();
  const { cityName } = useSelector(citySelector);
  const { cityData } = useSelector(cityDataSelector);
  const { summary } = useSelector(sumarrySelector);

  const [search, setSearchInput] = useState("");

  const handleInput = (e) => {
    setSearchInput(e.target.value);
  };

  const [showUl, setShowul] = useState(false);
  const handleShowUl = () => {
    setShowul(!showUl);
  };

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState(null);
  const [city, setCity] = useState();

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("Geolocation NOT suppoted");
    } else {
      setStatus("Locating...");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus(null);
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("Unable to get location.");
        }
      );
    }
  };

  let geoUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`;

  fetch(geoUrl)
    .then((response) => response.json())
    .then((data) => setCity(data?.city));

  useEffect(() => {
    dispatch(fetchCity());
    getLocation();
  }, []);

  const uniqueObjects = [
    ...new Map(cityName.map((item) => [item, item?.id])).values(),
  ];

  // console.log(`uniqueObjects:: `, uniqueObjects);

  const [valueOne, setValueOne] = useState(0);
  const handleChange = (event, newValue) => {
    setValueOne(newValue);
  };
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [value, setValue] = useState("1");

  const handleChangeTabs = (event, newTabValue) => {
    setValue(newTabValue);
  };

  const [searchKey, setSearchKey] = useState("");

  useEffect(() => {
    const handleSearchKey = () => {
      dispatch(fetchSummary("", searchKey));
    };
    handleSearchKey();
  }, [searchKey]);

  return (
    <>
      <div className="home-screen-search">
        <img src={backgroundimg} alt="bcklogo" className="home-image" />

        <div>
          <h1 className="h1-para">Your Dream | Our Passion</h1>
        </div>

        <div className="search-filter-container">
          <div className="search-filter-main-button">
            <ul className="search-filter-button">
              <button
                id={"0"}
                value={""}
                className={
                  active === "0" ? "active-search-tab" : "in-active-search-tab"
                }
                onClick={handleClick}
              >
                Buy
              </button>
              <button
                id={"1"}
                value={"plots"}
                className={
                  active === "1" ? "active-search-tab" : "in-active-search-tab"
                }
                onClick={handleClick}
              >
                Plots
              </button>
              <button
                id={"2"}
                value={"residential"}
                className={
                  active === "2" ? "active-search-tab" : "in-active-search-tab"
                }
                onClick={handleClick}
              >
                Residential
              </button>
              <button
                id={"3"}
                value={"commercial"}
                className={
                  active === "3" ? "active-search-tab" : "in-active-search-tab"
                }
                onClick={handleClick}
              >
                Commercial
              </button>
              <button
                id={"4"}
                className={
                  active === "4" ? "active-search-tab" : "in-active-search-tab"
                }
                onClick={handleClick}
              >
                Free Property Ad
              </button>
            </ul>
          </div>

          <div className="homepage-searchbar-container">
            <div className="container_form">
              <div className="dropdown">
                <input
                  className="main-search-input"
                  placeholder="Country, City, Address, Postal Code or ID"
                  type="text"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />

                <style>{`.element-visible { display: block } .element-hidden { display: none }`}</style>
                <img
                  src={filter}
                  alt="filter"
                  style={{ cursor: "pointer", padding: "10px" }}
                  onClick={() => setVisibleFilter(!visibleFilter)}
                />
                {/* <Link to="/property-in-india" className="linkclass"> */}
                <Link to={`/${summary[0]?.URL}`} className="linkclass">
                  <button className="btn1" onClick={FinalDispatchButton}>
                    <BsSearch size={20} />
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {searchKey?.length > 0 ? (
            <div className="search-box">
              <div className="search-result-li-container">
                {summary.length > 0
                  ? summary?.map((item, id) => {
                      return (
                        <Link to={`/${item.URL}`} className="linkclass">
                          <div key={id} className="search-result-li">
                            <span className="search-result-title">
                              {item.title}
                            </span>
                            <span className="search-result-property-type">
                              {item.SearchName}
                            </span>
                          </div>
                        </Link>
                      );
                    })
                  : "no result found"}
              </div>
            </div>
          ) : null}

          {/* Filters dropdown menu Starts*/}
          <div className={visibleFilter ? "element-visible" : "element-hidden"}>
            <div className="search_filter_main search-container ">
              <TabContext value={value}>
                <TabList
                  style={{
                    marginTop: "4.5%",
                    marginLeft: "3%",
                  }}
                  TabIndicatorProps={{
                    style: { display: "none", border: "none" },
                  }}
                  onChange={handleChangeTabs}
                >
                  <Tab
                    label="Budget"
                    value="1"
                    className="filter-btn"
                    icon={<IoIosArrowDown />}
                    iconPosition="end"
                  />
                  <Tab
                    label="Room"
                    value="2"
                    className="filter-btn"
                    icon={<IoIosArrowDown />}
                    iconPosition="end"
                  />
                  <Tab
                    label="Property Type"
                    value="3"
                    className="filter-btn"
                    icon={<IoIosArrowDown />}
                    iconPosition="end"
                  />
                  <Tab
                    label="Construction Status"
                    value="4"
                    className="filter-btn"
                    icon={<IoIosArrowDown />}
                    iconPosition="end"
                  />
                  <Tab
                    label="Posted By"
                    value="5"
                    className="filter-btn"
                    icon={<IoIosArrowDown />}
                    iconPosition="end"
                  />
                </TabList>
                <div>
                  <TabPanel value="1">
                    <div style={{ width: "91%", marginLeft: "3.6%" }}>
                      <p className="p_type_text">Select Price Range</p>
                      <div style={{ marginLeft: "1%" }}>
                        <Slider
                          valueLabelDisplay="auto"
                          step={1000000}
                          marks={customMarks}
                          min={3000000}
                          max={40000000}
                          value={homepageSearchFilterBudget}
                          onChange={handleRange}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="2">
                    <div style={{ width: "90%", marginLeft: "3.6%" }}>
                      <p className="p_type_text">No of Rooms</p>
                      <div>
                        <button
                          id={"1"}
                          value={1}
                          className={active === "1" ? "active" : "not-active"}
                          onClick={handleClickRoom}
                        >
                          <BsCheckLg className="btn-check" />1 RK/BHK
                        </button>
                        <button
                          id={"2"}
                          value={2}
                          className={active === "2" ? "active" : "not-active"}
                          onClick={handleClickRoom}
                        >
                          <BsCheckLg className="btn-check" />2 BHK
                        </button>
                        <button
                          id={"3"}
                          value={3}
                          className={active === "3" ? "active" : "not-active"}
                          onClick={handleClickRoom}
                        >
                          <BsCheckLg className="btn-check" />3 BHK
                        </button>
                        <button
                          id={"4"}
                          value={4}
                          className={active === "4" ? "active" : "not-active"}
                          onClick={handleClickRoom}
                        >
                          <BsCheckLg className="btn-check" />4 BHK
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="3">
                    <div style={{ width: "90%", marginLeft: "3.6%" }}>
                      <p className="p_type_text">Property Type</p>
                      <div>
                        <button
                          id={"5"}
                          value={"residential"}
                          className={active === "5" ? "active" : "not-active"}
                          onClick={handleClickType}
                        >
                          <BsCheckLg className="btn-check" />
                          Residential
                        </button>
                        <button
                          id={"6"}
                          value={"commercial"}
                          className={active === "6" ? "active" : "not-active"}
                          onClick={handleClickType}
                        >
                          <BsCheckLg className="btn-check" />
                          Commercial
                        </button>
                        <button
                          id={"7"}
                          value={"plots"}
                          className={active === "7" ? "active" : "not-active"}
                          onClick={handleClickType}
                        >
                          <BsCheckLg className="btn-check" />
                          Plots
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="4">
                    <div style={{ width: "90%", marginLeft: "3.6%" }}>
                      <p className="p_type_text">Construction Status</p>
                      <div>
                        <button
                          id={"7"}
                          className={active === "7" ? "active" : "not-active"}
                          onClick={handleClick}
                        >
                          <BsCheckLg className="btn-check" />
                          Under Construction
                        </button>
                        <button
                          id={"8"}
                          className={active === "8" ? "active" : "not-active"}
                          onClick={handleClick}
                        >
                          <BsCheckLg className="btn-check" />
                          Ready To Move
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="5">
                    <div style={{ width: "90%", marginLeft: "3.6%" }}>
                      <p className="p_type_text">Posted By</p>
                      <div>
                        <button
                          id={"9"}
                          className={active === "9" ? "active" : "not-active"}
                          onClick={handleClick}
                        >
                          <BsCheckLg className="btn-check" />
                          Owner
                        </button>
                        <button
                          id={"10"}
                          className={active === "10" ? "active" : "not-active"}
                          onClick={handleClick}
                        >
                          <BsCheckLg className="btn-check" />
                          Builder
                        </button>
                        <button
                          id={"11"}
                          className={active === "11" ? "active" : "not-active"}
                          onClick={handleClick}
                        >
                          <BsCheckLg className="btn-check" />
                          Dealer
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </TabContext>
            </div>
          </div>
          {/* Filters dropdown menu Ends*/}
        </div>
      </div>
    </>
  );
};

export default HomepageSearch;

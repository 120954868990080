import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { productSelector } from "../../../../redux/reducer/product";
import "./style.scss";

const TabButton = ({ label, active, onClick }) => (
  <button
    className={`tab-button ${active ? "active-tab" : ""}`}
    onClick={onClick}
  >
    {label}
  </button>
);
const FloorPlan = () => {
  const { product } = useSelector(productSelector);
  let floorPlan = product?.floorPlanArr;
  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(floorPlan);
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="floor-plan-container">
      <div className="floor-plan">
        <p style={{ fontFamily: "sans-serif", color: "#000000" }}>
          <span
            style={{
              textDecoration: "underline",
              textUnderlineOffset: "5px",
              textDecorationThickness: "2px",
              textDecorationColor: "#FFD194",
            }}
          >
            Price & Fl
          </span>
          oor Plan
        </p>
        <div className="main-floor-plan">
          <div className="tabs">
            <div className="tab-buttons">
              {data?.map((item, index) => (
                <TabButton
                  key={index}
                  label={item?.name}
                  active={activeTab === index}
                  onClick={() => handleTabClick(index)}
                />
              ))}
            </div>
            <div className="right-div">{product?.projectDetails?.area}</div>
            <div className="floor-plan-image-container">
              <img
                className="floor-plan-image"
                src={data[activeTab]?.image}
                alt="Activeimage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorPlan;

import {
  getnearByProperty,
  getnearByPropertySuccess,
  getnearByPropertyFailure,
} from "../reducer/nearByProperty";

import axios from "axios";

export const fetchNearByProperty = (cityId) => {
  // console.log(`cityId`, cityId)
  let city = cityId ? cityId : 1;
  return (dispatch) => {
    dispatch(getnearByProperty());
    axios
      .get(`/nearbyProperty?city=${city}`)
      .then((response) => dispatch(getnearByPropertySuccess(response.data)))
      .catch((error) => dispatch(getnearByPropertyFailure(error)));
  };
};

import {
  getallProjectList,
  getallProjectListSuccess,
  getallProjectListFailure,
} from "../reducer/allProjectList";
import axios from "axios";

export const fetchAllProjectList = (
  cityid,
  propertytype,
  devid,
  trending,
  size,
  urlcity,
  urldev,
  minRange,
  maxRange,
  startSize,
  endSize,
  locationCity,
  roomBhk,
  amentiesId
) => {
  let city = cityid ? cityid : "";
  let propertyType = propertytype ? propertytype : "";
  let dev = devid ? devid : "";
  let min = minRange ? minRange : "";
  let max = maxRange ? maxRange : "";
  let start = startSize ? startSize : "";
  let end = endSize ? endSize : "";
  let location = locationCity ? locationCity : "";
  let bhk = roomBhk ? roomBhk : "";
  let amenties = amentiesId ? amentiesId : "";
  let trendingProperty = trending ? trending : "";
  let pageIncrease = size ? size : 0;
  let urlCity = urlcity ? urlcity : "";
  let urlDev = urldev ? urldev : "";

  return (dispatch) => {
    dispatch(getallProjectList());
    axios
      .get(
        `getFilterProjects?city=${city}&type=${propertyType}&dev=${dev}&price_min=${min}&price_max=${max}&start_size=${start}&end_size=${end}&location=${location}&bhk=${bhk}&amenities=${amenties}&trending=${trendingProperty}&limit=10&offset=${pageIncrease}&cityName=${urlCity}&devName=${urlDev}`
      )
      .then((response) => {
        dispatch(getallProjectListSuccess(response.data));
      })
      .catch((error) => dispatch(getallProjectListFailure(error)));
  };
};

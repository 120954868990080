import { IconButton } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import React, { useState, useEffect } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { fetchAllProjectList } from "../../redux/action/allProjectList";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginTop: "-12px",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AdvanceFilter = () => {
  const [expandedBudget, setExpandedBudget] = useState(true);
  const [expandedPropertyType, setExpandedPropertyType] = useState(true);
  const [expandedBhkType, setExpandedBhkType] = useState(true);
  const [expandedLocality, setExpandedLocality] = useState(true);
  const [expandedSaleType, setExpandedSaleType] = useState(true);
  const [expandedConstructionStatus, setExpandedConstructionStatus] =
    useState(true);
  const [expandedBathrooms, setExpandedBathrooms] = useState(true);
  const [expandedArea, setExpandedArea] = useState(true);
  const [expandedAmenities, setExpandedAmenities] = useState(true);
  const [expandedAge, setExpandedAge] = useState(true);
  const [expandedFacing, setExpandedFacing] = useState(true);
  const [expandedDetail, setExpandedDetail] = useState(true);
  const [expandedRera, setExpandedRera] = useState(true);
  const [active, setActive] = useState("");

  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const handleExpandBudget = () => {
    setExpandedBudget(!expandedBudget);
  };
  const handleExpandPropertyType = () => {
    setExpandedPropertyType(!expandedPropertyType);
  };
  const handleExpandBhkType = () => {
    setExpandedBhkType(!expandedBhkType);
  };
  const handleExpandLocality = () => {
    setExpandedLocality(!expandedLocality);
  };
  const handleExpandSaleType = () => {
    setExpandedSaleType(!expandedSaleType);
  };
  const handleExpandConstructionStatus = () => {
    setExpandedConstructionStatus(!expandedConstructionStatus);
  };
  const handleExpandBathrooms = () => {
    setExpandedBathrooms(!expandedBathrooms);
  };
  const handleExpandArea = () => {
    setExpandedArea(!expandedArea);
  };
  const handleExpandAmenities = () => {
    setExpandedAmenities(!expandedAmenities);
  };
  const handleExpandAge = () => {
    setExpandedAge(!expandedAge);
  };
  const handleExpandFacing = () => {
    setExpandedFacing(!expandedFacing);
  };
  const handleExpandDetail = () => {
    setExpandedDetail(!expandedDetail);
  };
  const handleExpandRera = () => {
    setExpandedRera(!expandedRera);
  };

  let min = 3000000;
  let max = 40000000;

  const [range, setRange] = useState([min, max]);

  const handleRange = (event) => {
    setRange(event.target.value);
    console.log(`event`, event.target.value);
    dispatch(
      fetchAllProjectList("", "", "", "", "", "", "", range[0], range[1])
    );
  };
  const customMarks = [
    {
      value: 3000000,
      label: "₹30L",
    },
    {
      value: 10000000,
      label: "₹1CR",
    },
    {
      value: 20000000,
      label: "₹2CR",
    },
    {
      value: 30000000,
      label: "₹3CR",
    },
    {
      value: 40000000,
      label: "₹4CR",
    },
  ];

  let minArea = 0;
  let maxArea = 5000;

  const [rangeArea, setRangeArea] = useState([minArea, maxArea]);

  const handleRangeArea = (event) => {
    setRangeArea(event.target.value);
    dispatch(
      fetchAllProjectList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        rangeArea[0],
        rangeArea[1]
      )
    );
  };

  const customMarksArea = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 1000,
      label: "1000",
    },
    {
      value: 2000,
      label: "2000",
    },
    {
      value: 3000,
      label: "3000",
    },
    {
      value: 4000,
      label: "4000",
    },
    {
      value: 5000,
      label: "5000+",
    },
  ];
  const dispatch = useDispatch();

  const [locations, setLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  useEffect(() => {
    // Fetch data from API and set locations state
    fetch(`https://nodeapi.seedwill.co/api/v1/locationList?city=${1}`)
      .then((response) => response.json())
      .then((data) => setLocations(data.data))
      .catch((error) => console.log(error));
  }, []);

  const handleLocationSelect = (event) => {
    setSelectedLocations(event.target.value);
    dispatch(
      fetchAllProjectList(
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        selectedLocations
      )
    );
  };

  return (
    <>
      <div className="filter-container">
        <div className="filter-text-heading">
          <p className="header-text">Filter</p>
          <p className="clear-all">Clear All</p>
        </div>

        <div className="filter-type-box">
          <div className="filter-text-heading">Budget</div>
          <ExpandMore
            expand={expandedBudget}
            onClick={handleExpandBudget}
            aria-expanded={expandedBudget}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <div>
          <Collapse in={expandedBudget}>
            <div style={{ padding: "0px 10px 0px 10px" }}>
              <Slider
                valueLabelDisplay="auto"
                step={1000000}
                marks={customMarks}
                min={3000000}
                max={40000000}
                value={range}
                onChange={handleRange}
              />
            </div>
            {/* <div className="filter-type-box">
              <input placeholder="Min: ₹" className="filter-budget-textbox" />
              <input placeholder="Min: ₹" className="filter-budget-textbox" />
            </div> */}
          </Collapse>
        </div>

        <div className="filter-type-box">
          <div className="filter-text-heading">Property Type</div>
          <ExpandMore
            expand={expandedPropertyType}
            aria-expanded={expandedPropertyType}
            onClick={handleExpandPropertyType}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <Collapse in={expandedPropertyType}>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() => dispatch(fetchAllProjectList("", "commercial"))}
            >
              Commercial
            </button>
            <button
              key={2}
              id={"2"}
              className={active === "2" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() => dispatch(fetchAllProjectList("", "residential"))}
            >
              Residential
            </button>
            <button
              key={3}
              id={"3"}
              className={active === "3" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() => dispatch(fetchAllProjectList("", "plots"))}
            >
              Plots
            </button>
          </div>
        </Collapse>

        <div className="filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Bhk Type</div>
          <ExpandMore
            expand={expandedBhkType}
            aria-expanded={expandedBhkType}
            onClick={handleExpandBhkType}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <Collapse in={expandedBhkType}>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() =>
                dispatch(
                  fetchAllProjectList(
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    1
                  )
                )
              }
            >
              1RK/ 1BHK
            </button>
            <button
              key={2}
              id={"2"}
              className={active === "2" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() =>
                dispatch(
                  fetchAllProjectList(
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    2
                  )
                )
              }
            >
              2 BHK
            </button>
            <button
              key={3}
              id={"3"}
              className={active === "3" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() =>
                dispatch(
                  fetchAllProjectList(
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    3
                  )
                )
              }
            >
              3 BHK
            </button>
            <button
              key={4}
              id={"4"}
              className={active === "4" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() =>
                dispatch(
                  fetchAllProjectList(
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    4
                  )
                )
              }
            >
              4 BHK
            </button>
            <button
              key={5}
              id={"5"}
              className={active === "5" ? "active" : "not-active"}
              // onClick={handleClick}
              onClick={() =>
                dispatch(
                  fetchAllProjectList(
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    "",
                    5
                  )
                )
              }
            >
              5 BHK
            </button>
          </div>
        </Collapse>

        <div className="filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Locality</div>
          <ExpandMore
            expand={expandedLocality}
            aria-expanded={expandedLocality}
            onClick={handleExpandLocality}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <Collapse in={expandedLocality}>
          <div>
            <select
              // multiple
              value={selectedLocations}
              onChange={handleLocationSelect}
            >
              {locations.map((item, index) => {
                return (
                  <option key={item.index} value={item.location}>
                    {item.location}
                  </option>
                );
              })}
            </select>
          </div>
        </Collapse>

        <div className="filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Construction Status</div>
          <ExpandMore
            expand={expandedConstructionStatus}
            aria-expanded={expandedConstructionStatus}
            onClick={handleExpandConstructionStatus}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <Collapse in={expandedConstructionStatus} timeout="auto" unmountOnExit>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Under Construction
            </button>
            <button
              key={2}
              id={"2"}
              className={active === "2" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Ready to Move
            </button>
          </div>
        </Collapse>

        <p className="header-text">Features</p>

        <div className="filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Bathrooms</div>
          <ExpandMore
            expand={expandedBathrooms}
            aria-expanded={expandedBathrooms}
            onClick={handleExpandBathrooms}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <Collapse in={expandedBathrooms}>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              1+
            </button>
            <button
              key={2}
              id={"2"}
              className={active === "2" ? "active" : "not-active"}
              onClick={handleClick}
            >
              2+
            </button>
            <button
              key={3}
              id={"3"}
              className={active === "3" ? "active" : "not-active"}
              onClick={handleClick}
            >
              3+
            </button>
            <button
              key={4}
              id={"4"}
              className={active === "4" ? "active" : "not-active"}
              onClick={handleClick}
            >
              4+
            </button>
            <button
              key={5}
              id={"5"}
              className={active === "5" ? "active" : "not-active"}
              onClick={handleClick}
            >
              5+
            </button>
          </div>
        </Collapse>

        <div className="filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Build Up Area (Sq. Ft.)</div>
          <ExpandMore
            expand={expandedArea}
            aria-expanded={expandedArea}
            onClick={handleExpandArea}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <div padding={"10px"}>
          <Collapse in={expandedArea}>
            <div style={{ padding: "0px 15px 0px 10px" }}>
              <Slider
                valueLabelDisplay="auto"
                step={100}
                marks={customMarksArea}
                min={0}
                max={5000}
                value={rangeArea}
                onChange={handleRangeArea}
              />
            </div>
          </Collapse>
        </div>

        <div className="filter-type-box" marginTop={"20px"}>
          <div className="filter-text-heading">Amenities</div>
          <ExpandMore
            expand={expandedAmenities}
            aria-expanded={expandedAmenities}
            onClick={handleExpandAmenities}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <Collapse in={expandedAmenities}>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Gated Community
            </button>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Lift
            </button>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Swimming Pool
            </button>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Gym
            </button>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Parking
            </button>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              Gas Pipeline
            </button>
          </div>
        </Collapse>
        <div className="filter-type-box" style={{ marginTop: "20px" }}>
          <div className="filter-text-heading">Rera Approved</div>
          <ExpandMore
            expand={expandedRera}
            aria-expanded={expandedRera}
            onClick={handleExpandRera}
          >
            <MdKeyboardArrowDown className="expandmore-button" />
          </ExpandMore>
        </div>
        <Collapse in={expandedRera}>
          <div>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              RERA Approved Properties
            </button>
            <button
              key={1}
              id={"1"}
              className={active === "1" ? "active" : "not-active"}
              onClick={handleClick}
            >
              RERA Registered Dealers
            </button>
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default AdvanceFilter;

import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { FaRegHeart } from "react-icons/fa";
import { ImSortAmountDesc } from "react-icons/im";
import { IoIosArrowForward } from "react-icons/io";
import { IoGridSharp } from "react-icons/io5";
import { MdIosShare, MdVerified } from "react-icons/md";
import { RiLayout4Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import agent from "../../asset/images/agent.jpeg";
import { fetchAllProjectList } from "../../redux/action/allProjectList";
import { fetchProduct } from "../../redux/action/product";
import { allProjectListSelector } from "../../redux/reducer/allProjectList";
import ScrollButton from "../MoveToTopButton/ScrollButton";
import AdvanceFilter from "./AdvanceFilter";
import Navbar from "./Navbar";
import "./style.scss";

const Property = () => {
  const addImageFallback = (event) => {
    event.currentTarget.src =
      "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjCN1Fg1nNOg8sc76MR64L7ZBPWuYs6c1BGg&usqp=CAU";
  };
  const date = new Date();
  let day = date.getDate();
  let year = date.getFullYear();
  let currentDate = `Feb ${day}, ${year}`;

  const [showUi, setShowUi] = useState(false);
  const handleshowUi = () => {
    setShowUi(true);
  };
  const handleshowUiTwo = () => {
    setShowUi(false);
  };
  const [active, setActive] = useState("");
  const handleClick = (event) => {
    setActive(event.target.id);
  };

  const dispatch = useDispatch();
  const { allProjectList } = useSelector(allProjectListSelector);

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  // infinitescrolling
  const [page, setPage] = useState(1);
  const [card, setCard] = useState([]);

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const fullHeight = document.body.offsetHeight;
      const scrollPoint = window.scrollY + windowHeight;
      if (scrollPoint >= fullHeight) {
        setPage((prev) => prev + 1);
        setCard((prev) => [...prev, ...allProjectList]);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [page]);

  //...........//

  // use params section.....................//
  // const { propertyincity } = useParams();
  // let urlParam = propertyincity;
  let paramsInUrl = window.location.pathname;

  useEffect(() => {
    if (paramsInUrl.includes("residential-property-in-india")) {
      dispatch(fetchAllProjectList("", "residential", "", "", page));
    } else if (paramsInUrl.includes("commercial-property-in-india")) {
      dispatch(fetchAllProjectList("", "commercial", "", "", page));
    } else if (paramsInUrl.includes("plots-property-in-india")) {
      dispatch(fetchAllProjectList("", "plots", "", "", page));
    } else if (paramsInUrl.includes("office-property-in-india")) {
      dispatch(fetchAllProjectList("", "office", "", "", page));
    } else if (paramsInUrl.includes("penthouse-property-in-india")) {
      dispatch(fetchAllProjectList("", "penthouse", "", "", page));
    } else if (paramsInUrl.includes("shop-property-in-india")) {
      dispatch(fetchAllProjectList("", "shop", "", "", page));
    } else if (paramsInUrl.includes("villa-property-in-india")) {
      dispatch(fetchAllProjectList("", "villa", "", "", page));
    } else if (paramsInUrl.includes("/trending")) {
      dispatch(fetchAllProjectList("", "", "", 1, page));
    } else if (paramsInUrl.includes("property-in" && "residential")) {
      const originalString = paramsInUrl;
      const substringToRemove1 = "/property-in-";
      const substringToRemove2 = "/residential";

      const newString = originalString.replace(
        new RegExp(substringToRemove1, "g"),
        ""
      );
      const cityNameInUrl = newString.replace(
        new RegExp(substringToRemove2, "g"),
        ""
      );
      dispatch(
        fetchAllProjectList("", "residential", "", "", "", cityNameInUrl)
      );
    } else if (paramsInUrl.includes("property-in" && "commercial")) {
      const originalString = paramsInUrl;
      const substringToRemove1 = "/property-in-";
      const substringToRemove2 = "/commercial";

      const newString = originalString.replace(
        new RegExp(substringToRemove1, "g"),
        ""
      );
      const cityNameInUrl = newString.replace(
        new RegExp(substringToRemove2, "g"),
        ""
      );
      dispatch(
        fetchAllProjectList("", "commercial", "", "", "", cityNameInUrl)
      );
    } else if (paramsInUrl.includes("/property-in-")) {
      //for city param
      const originalString = paramsInUrl;
      const substringToRemove1 = "/property-in-";
      const urlcity = originalString.replace(
        new RegExp(substringToRemove1, "g"),
        ""
      );
      dispatch(fetchAllProjectList("", "", "", "", page, urlcity));
    } else if (paramsInUrl.includes("-projects-list")) {
      // for developer param
      const originalString = paramsInUrl;
      const substringToRemove = "-projects-list/";
      const newString = originalString.replace(
        new RegExp(substringToRemove, "g"),
        ""
      );
      let urlDevName = newString.replace(/\//g, "");
      dispatch(fetchAllProjectList("", "", "", "", "", "", urlDevName));
    } else {
      console.log("Path not detected");
      function redirect(path) {
        setTimeout(() => {
          window.location.href = path;
        }, 500);
      }
      redirect("/");
    }
  }, [page, paramsInUrl]);

  // use params section.....................//

  return (
    <>
      <Navbar />
      <ScrollButton />

      <div className="property">
        <div
          style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)" }}
        >
          <div style={{ gridColumn: "span 9" }}>
            <div className="propertypage-header-container">
              <Link to="/" className="linkclass1">
                Home
              </Link>
              <IoIosArrowForward size={20} />
              {window.location.pathname.includes("india") ? (
                <div className="propertypage-header-container">
                  Property in India
                </div>
              ) : (
                <div className="propertypage-header-container">
                  <Link
                    to={`/property-in-${allProjectList[0]?.cityName.toLowerCase()}`}
                    className="linkclass1"
                  >
                    Property in {allProjectList[0]?.cityName}
                  </Link>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              gridColumn: "span 3",
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <span style={{ fontSize: "12px", color: "#8B8B8B" }}>
              Last Updated: {currentDate}
            </span>
          </div>
        </div>
        <div
          style={{ display: "grid", gridTemplateColumns: "repeat(12, 1fr)" }}
        >
          {/* filter menu Starts */}
          <Box gridColumn="span 3">
            <AdvanceFilter />
          </Box>
          {/* filter menu Ends */}

          {/* Property details Starts */}
          <Box gridColumn="span 9">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(12, 1fr)",
                marginBottom: "2%",
              }}
            >
              <div style={{ gridColumn: "span 8" }}>
                <div className="propertypage-header-container">
                  {window.location.pathname.includes("india") ? (
                    <span style={{ fontSize: "14px" }}>
                      Property in India for Sale
                    </span>
                  ) : (
                    <span style={{ fontSize: "14px" }}>
                      Property in {allProjectList[0]?.cityName} for Sale
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  gridColumn: "span 4",
                  backgroundColor: "transparent",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", gap: "5px" }}>
                    <ImSortAmountDesc size={20} />
                    <Typography backgroundColor="#F5FAF3" marginTop="-3px">
                      <span style={{ fontSize: "14px" }}>Sort By</span>
                    </Typography>
                  </div>

                  <div className="sort-section">
                    <div className="sort-selection">
                      <form action="#">
                        <select
                          name="sort"
                          id="sort"
                          className="sort-selection--style"
                        >
                          <option value="lowest">Relevance</option>
                          <option value="lowest">Newest First</option>
                          <option value="lowest">Price Low to High</option>
                          <option value="highest">Price High to Low</option>
                          <option value="a-z">
                            price / sq.ft : low to high
                          </option>
                          <option value="z-a">
                            price / sq.ft : high to low
                          </option>
                        </select>
                      </form>
                    </div>
                  </div>
                  <RiLayout4Fill
                    onClick={(event) => {
                      handleshowUi();
                      handleClick(event);
                    }}
                    // onClick={handleshowUi handleClick}
                    key={1}
                    id={"1"}
                    className={
                      active === "1" ? "active-layout" : "not-active-layout"
                    }
                  />
                  <IoGridSharp
                    onClick={(event) => {
                      handleshowUiTwo();
                      handleClick(event);
                    }}
                    key={2}
                    id={"2"}
                    className={
                      active === "2" ? "active-layout" : "not-active-layout"
                    }
                  />
                </div>
              </div>
            </div>

            {showUi ? (
              <div className="property-card-main">
                {card.length > 9
                  ? card?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{ marginBottom: "15px", marginRight: "15px" }}
                          onClick={(e) => dispatch(fetchProduct(item.id))}
                        >
                          <Link
                            to={`/property-in-${item.cityName.toLowerCase()}/${
                              item.slug
                            }-${item.id}`}
                            className="linkclass"
                          >
                            <div className="property-card">
                              <div className="price-tag">₹ {item.price}</div>
                              <Link
                                to={`/property-in-${item.cityName.toLowerCase()}/${
                                  item.slug
                                }-${item.id}`}
                              >
                                <img
                                  src={item.proImg}
                                  alt="property"
                                  onError={addImageFallback}
                                  style={{
                                    width: "100%",
                                    height: "180px",
                                    borderTopRightRadius: "6px",
                                    borderTopLeftRadius: "6px",
                                  }}
                                />
                              </Link>
                              <div className="property-grid-layout-container">
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(12, 1fr)",
                                  }}
                                >
                                  <div style={{ gridColumn: "span 10" }}>
                                    <span className="property-name">
                                      {item.name}
                                    </span>
                                  </div>
                                  <div style={{ gridColumn: "span 2" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        justifyItems: "center",
                                      }}
                                    >
                                      <MdIosShare
                                        color="#FFD194"
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                      />
                                      <FaRegHeart
                                        color="#FFD194"
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <span className="property-address">
                                  {item.location}, {item.cityName}
                                </span>
                                <div className="property-detail-grid-container">
                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      3 Bhk
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                      />
                                    </span>
                                  </div>

                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      {item.property_type}{" "}
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      {item.area}{" "}
                                    </span>
                                  </div>
                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      />
                                      Ready to move
                                    </span>
                                  </div>
                                </div>

                                <Divider
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "22px",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(12, 1fr)",
                                  }}
                                >
                                  <div
                                    style={{
                                      gridColumn: "span 2",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    <IconButton style={{ padding: "0" }}>
                                      <MdVerified
                                        color="#6aa74d"
                                        className="verified-badge"
                                      />
                                      <Avatar alt="Profile" src={agent} />
                                    </IconButton>
                                  </div>
                                  <span
                                    style={{
                                      gridColumn: "span 6",
                                      marginLeft: "10px",
                                      fontSize: "10px",
                                      color: "#8b8b8b",
                                    }}
                                  >
                                    SEEDWILL EXPERT
                                  </span>
                                  <div
                                    style={{
                                      gridColumn: "span 4",
                                      marginTop: "-14px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      className="contact-btn"
                                      color="success"
                                    >
                                      contact
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  : allProjectList?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          style={{ marginBottom: "15px", marginRight: "15px" }}
                          onClick={(e) => dispatch(fetchProduct(item.id))}
                        >
                          <Link
                            to={`/property-in-${item.cityName.toLowerCase()}/${
                              item.slug
                            }-${item.id}`}
                            className="linkclass"
                          >
                            <div className="property-card">
                              <div className="price-tag">₹ {item.price}</div>
                              <Link
                                to={`/property-in-${item.cityName.toLowerCase()}/${
                                  item.slug
                                }-${item.id}`}
                              >
                                <img
                                  src={item.proImg}
                                  alt="property"
                                  onError={addImageFallback}
                                  style={{
                                    width: "100%",
                                    height: "180px",
                                    borderTopRightRadius: "6px",
                                    borderTopLeftRadius: "6px",
                                  }}
                                />
                              </Link>
                              <div className="property-grid-layout-container">
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(12, 1fr)",
                                  }}
                                >
                                  <div style={{ gridColumn: "span 10" }}>
                                    <span className="property-name">
                                      {" "}
                                      {item.name}
                                    </span>
                                  </div>
                                  <div style={{ gridColumn: "span 2" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        justifyItems: "center",
                                      }}
                                    >
                                      <MdIosShare
                                        color="#FFD194"
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                      />
                                      <FaRegHeart
                                        color="#FFD194"
                                        size={20}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <span className="property-address">
                                  {item.location}, {item.cityName}
                                </span>
                                <div className="property-detail-grid-container">
                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      3 Bhk
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                      />
                                    </span>
                                  </div>

                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      {item.property_type}{" "}
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          marginLeft: "10px",
                                        }}
                                      />
                                    </span>
                                  </div>
                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      {item.area}{" "}
                                    </span>
                                  </div>
                                  <div className="property-detail-grid-item">
                                    <span className="property-detail-subtext">
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          marginRight: "10px",
                                        }}
                                      />
                                      Ready to move
                                    </span>
                                  </div>
                                </div>

                                <Divider
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "22px",
                                  }}
                                />
                                <div
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "repeat(12, 1fr)",
                                  }}
                                >
                                  <div
                                    style={{
                                      gridColumn: "span 2",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    <IconButton style={{ padding: "0" }}>
                                      <MdVerified
                                        color="#6aa74d"
                                        className="verified-badge"
                                      />
                                      <Avatar alt="Profile" src={agent} />
                                    </IconButton>
                                  </div>
                                  <span
                                    style={{
                                      gridColumn: "span 6",
                                      marginLeft: "10px",
                                      fontSize: "10px",
                                      color: "#8b8b8b",
                                    }}
                                  >
                                    SEEDWILL EXPERT
                                  </span>
                                  <div
                                    style={{
                                      gridColumn: "span 4",
                                      marginTop: "-14px",
                                    }}
                                  >
                                    <Button
                                      variant="contained"
                                      className="contact-btn"
                                      color="success"
                                    >
                                      contact
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
              </div>
            ) : (
              <>
                {card.length > 9
                  ? card?.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() => dispatch(fetchProduct(item.id))}
                        >
                          <Link
                            to={`/property-in-${item.cityName.toLowerCase()}/${
                              item.slug
                            }-${item.id}`}
                            className="linkclass"
                          >
                            <div className="card-list">
                              <div style={{ display: "flex", width: "100%" }}>
                                <p className="price-tag ">{item.price}</p>
                                <img
                                  src={item.proImg}
                                  alt="card"
                                  style={{
                                    borderTopLeftRadius: "6px",
                                    borderBottomLeftRadius: "6px",
                                  }}
                                />

                                <div className="card-list-container">
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      fontFamily: "Montserrat",
                                      color: "#8B8B8B",
                                    }}
                                  >
                                    {item.DevName} located in {item.location},{" "}
                                    {item.cityName}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        Rooms
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.bhk}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>

                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        Property type
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.property_type}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        FURNISHING
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.furnishing}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        BUILT-UP AREA
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.area}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        Average price
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.price}
                                      </p>
                                    </div>
                                  </div>

                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#8B8B8B",
                                    }}
                                  >
                                    Godrej properties, one of the oldest
                                    veterans of the realty sector in India have
                                    recently residential in Gurgaon{" "}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "#6aa74d ",
                                      }}
                                    >
                                      read more
                                    </span>
                                  </p>

                                  <div
                                    style={{ border: "1px solid #ECECEC" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        fontSize: "12px",
                                        color: "#8B8B8B",
                                      }}
                                    >
                                      <IconButton style={{ padding: "0" }}>
                                        <MdVerified
                                          color="#6aa74d"
                                          className="verified-badge"
                                        />
                                        <Avatar alt="Profile" src={agent} />
                                      </IconButton>
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "15px",
                                        }}
                                      >
                                        Seedwill Expert
                                      </span>
                                    </div>

                                    <button
                                      style={{
                                        width: "120px",
                                        background: "#6AA74D",
                                        color: "#FFFFFF",
                                        borderRadius: "6px",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Contact
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  : allProjectList.map((item, index) => {
                      return (
                        <div
                          // className="card-list"
                          key={index}
                          onClick={() => dispatch(fetchProduct(item.id))}
                        >
                          <Link
                            to={`/property-in-${item.cityName.toLowerCase()}/${
                              item.slug
                            }-${item.id}`}
                            className="linkclass"
                          >
                            <div className="card-list">
                              <div style={{ display: "flex", width: "100%" }}>
                                <p className="price-tag ">{item.price}</p>
                                <img
                                  src={item.proImg}
                                  alt="card"
                                  style={{
                                    borderTopLeftRadius: "6px",
                                    borderBottomLeftRadius: "6px",
                                  }}
                                />

                                <div className="card-list-container">
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "600",
                                    }}
                                  >
                                    {item.name}
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "500",
                                      fontFamily: "Montserrat",
                                      color: "#8B8B8B",
                                    }}
                                  >
                                    {item.DevName} located in {item.location},{" "}
                                    {item.cityName}
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      fontSize: "12px",
                                    }}
                                  >
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        Rooms
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.bhk}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>

                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        Property type
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.property_type}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        FURNISHING
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.furnishing}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        BUILT-UP AREA
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.area}
                                      </p>
                                    </div>
                                    <div>
                                      <Divider
                                        orientation="vertical"
                                        style={{
                                          width: "10px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: "10px",
                                          color: "#8B8B8B",
                                        }}
                                      >
                                        Average price
                                      </span>
                                      <p
                                        style={{
                                          marginTop: "3px",
                                          fontFamily: "Montserrat",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {item.price}
                                      </p>
                                    </div>
                                  </div>

                                  <p
                                    style={{
                                      fontSize: "12px",
                                      color: "#8B8B8B",
                                    }}
                                  >
                                    Godrej properties, one of the oldest
                                    veterans of the realty sector in India have
                                    recently residential in Gurgaon{" "}
                                    <span
                                      style={{
                                        fontSize: "13px",
                                        color: "#6aa74d ",
                                      }}
                                    >
                                      read more
                                    </span>
                                  </p>

                                  <div
                                    style={{ border: "1px solid #ECECEC" }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "10px",
                                        fontSize: "12px",
                                        color: "#8B8B8B",
                                      }}
                                    >
                                      <IconButton style={{ padding: "0" }}>
                                        <MdVerified
                                          color="#6aa74d"
                                          className="verified-badge"
                                        />
                                        <Avatar alt="Profile" src={agent} />
                                      </IconButton>
                                      <span
                                        style={{
                                          marginLeft: "10px",
                                          marginTop: "15px",
                                        }}
                                      >
                                        Seedwill Expert
                                      </span>
                                    </div>

                                    <button
                                      style={{
                                        width: "120px",
                                        background: "#6AA74D",
                                        color: "#FFFFFF",
                                        borderRadius: "6px",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Contact
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })}
              </>
            )}
          </Box>
        </div>
      </div>
    </>
  );
};

export default Property;
